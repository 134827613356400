import { Box, Stack } from "@mui/material";
import React, { PropsWithChildren, useContext, useEffect, useState } from "react";
import { Optional } from "sonobello.utilities.react.mui";

import AppContext from "../../../AppContext";
import { Step } from "../../../types/Step";
import { isMobileView } from "../../../utils/Constants";
import UserEventNextButtonDesktop from "../../../V2/Steps/Compositions/UserEventNextButtonDesktop";
import UserEventNextButtonMobile from "../../../V2/Steps/Compositions/UserEventNextButtonMobile";
import { IFlowStepProps } from "../../../V2/Types/IFlow";

interface FlowStepWrapperProps {
  /** When provided override the next button's label with this value. Defaults to `"Next"`. */
  nextButtonLabel?: string;
  /** When provided overrides the onClick handler for  the next button.
   * Defaults to the current flow's configured handler for this step.
   */
  nextButtonOnClick?: () => void;
  /** Flag which overrides the next button's icon to show a `CircularProgress` when `true`. */
  nextButtonLoading?: boolean;
  /** Flag which overrides the next button's rendering altogether. Defaults to true.*/
  nextButtonRendered?: boolean;
}

const StepWrapper: React.FC<PropsWithChildren<FlowStepWrapperProps & IFlowStepProps>> = ({
  children,
  flowStep,
  nextButtonLabel = "Next",
  nextButtonLoading = false,
  nextButtonRendered = true,
  nextButtonOnClick,
  setStep
}) => {
  const contextProps = useContext(AppContext);
  const showMobile = isMobileView();
  const [nextStep, setNextStep] = useState<Optional<Step>>(
    flowStep.nextStep ? flowStep.nextStep(contextProps) : undefined
  );
  useEffect(
    () => setNextStep(flowStep.nextStep ? flowStep.nextStep(contextProps) : undefined),
    [flowStep, contextProps]
  );

  const onClick = nextButtonOnClick || (() => setStep(nextStep as Step));
  const disabled = !nextStep && Boolean(flowStep.nextStep);
  const label = nextButtonLabel.toUpperCase();

  return (
    <Box display="flex" flexGrow={1}>
      <Stack sx={{ display: "flex", flexGrow: 1, pb: showMobile ? 4 : undefined }}>
        <Box display="flex" flexGrow={1} overflow="auto" px={showMobile ? undefined : 6}>
          {children}
        </Box>
        {nextButtonRendered &&
          (showMobile ? (
            <UserEventNextButtonMobile
              label={label}
              disabled={disabled}
              isLoading={nextButtonLoading}
              onClick={onClick}
            />
          ) : (
            <UserEventNextButtonDesktop
              label={label}
              disabled={disabled}
              isLoading={nextButtonLoading}
              onClick={onClick}
            />
          ))}
      </Stack>
    </Box>
  );
};

export default StepWrapper;
