import React, { useEffect } from "react";
import { IControlledMutator, Nullable, TriggeredHook } from "sonobello.utilities.react";

export interface IDeviceReport {
  /** The browser which is being used in this local session. */
  browser: string;
  /** The browser version which is being used in this local session. */
  browserVersion: string;
  /** The name of the device operating system associated with this local session. */
  device: Nullable<string>;
  /** The lead id associated with the obx session. */
  leadId: string;
}

export type IDeviceInfoReporterProps = IControlledMutator<Nullable<boolean>>;

export interface IDeviceInfoReporterConfig {
  /** The report containing the device and browser info. */
  deviceReport: IDeviceReport;
  /** A hook which dispatches the triggered device report, and returns a flag indicating the receipt success state. */
  useReportDevice: TriggeredHook<boolean, void, boolean, IDeviceReport>;
}

/** Executes the report device hook if the initial controlled state is not `true`. After the hook is executed successfully,
 * dispatches the controlled state update for `true`.
 */
const DeviceInfoReporter: React.FC<IDeviceInfoReporterConfig & IDeviceInfoReporterProps> = ({
  deviceReport,
  value,
  onChange,
  useReportDevice
}: IDeviceInfoReporterConfig & IDeviceInfoReporterProps) => {
  const { result, execute } = useReportDevice(deviceReport);

  useEffect(() => {
    if (!value) execute();
  }, []);

  useEffect(() => {
    if (result) onChange(true);
  }, [result]);

  return null;
};

export default DeviceInfoReporter;
