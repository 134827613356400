import { Box, Stack } from "@mui/material";
import React, { DispatchWithoutAction } from "react";
import { IDisable, ILoaded } from "sonobello.utilities.react";

import { AppContextProps } from "../../../../AppContext";
import { isMobileView } from "../../../../utils/Constants";
import { IFlowStepProps } from "../../../Types/IFlow";
import NeedHelpDialog from "../../../Views/Components/NeedHelpDialog";
import StepWrapper, { IStepWrapperButtonProps } from "../../Components/StepWrapper";

export interface IWelcomeStepWrapperProps {
  /** The full context properties of the session. */
  appContextProps: AppContextProps;
}

export interface IWelcomeStepWrapperConfig {
  /** The component which draws the content of the welcome step. */
  StepComponent: React.FC;
}

const nextButtonLabel = "BOOK NOW";

const DesktopWelcomeStepWrapper: React.FC<
  IWelcomeStepWrapperConfig &
    Pick<IStepWrapperButtonProps, "NextButtonDesktop"> & { onClickNext: DispatchWithoutAction } & IDisable &
    ILoaded
> = ({ onClickNext, disabled, isLoading, NextButtonDesktop, StepComponent }) => {
  return (
    <Box display="flex" flexGrow={1}>
      <Stack spacing={6} sx={{ height: "100%", pt: "15%" }}>
        <StepComponent />
        <NextButtonDesktop label={nextButtonLabel} onClick={onClickNext} disabled={disabled} isLoading={isLoading} />
        <NeedHelpDialog />
      </Stack>
    </Box>
  );
};

const MobileWelcomeStepWrapper: React.FC<
  IWelcomeStepWrapperConfig & IStepWrapperButtonProps & { onClickNext: DispatchWithoutAction } & IDisable & ILoaded
> = ({ onClickNext, disabled, isLoading, NextButtonDesktop, NextButtonMobile, StepComponent }) => {
  return (
    <StepWrapper
      NextButtonMobile={NextButtonMobile}
      NextButtonDesktop={NextButtonDesktop}
      disabled={disabled}
      onNext={onClickNext}
      nextButtonLoading={isLoading}
    >
      <Stack spacing={6} sx={{ height: "100%", pt: "15%" }}>
        <StepComponent />
        <NeedHelpDialog />
      </Stack>
    </StepWrapper>
  );
};

/** A wrapper for all welcome steps which mounts the configured content within the {@link StepWrapper} with default behaviors. */
const WelcomeStepWrapper: React.FC<
  IWelcomeStepWrapperConfig & IFlowStepProps & IWelcomeStepWrapperProps & IStepWrapperButtonProps
> = ({ appContextProps, flowStep, setStep, NextButtonMobile, NextButtonDesktop, StepComponent }) => {
  const showMobile = isMobileView();
  const nextStep = flowStep.nextStep && flowStep.nextStep(appContextProps);
  const nextStepDisabled = nextStep === null || nextStep === undefined;
  const onNext = nextStepDisabled ? () => null : () => setStep(nextStep!);
  return showMobile ? (
    <MobileWelcomeStepWrapper
      onClickNext={onNext}
      disabled={nextStepDisabled}
      isLoading={false}
      NextButtonDesktop={NextButtonDesktop}
      NextButtonMobile={NextButtonMobile}
      StepComponent={StepComponent}
    />
  ) : (
    <DesktopWelcomeStepWrapper
      onClickNext={onNext}
      disabled={nextStepDisabled}
      isLoading={false}
      NextButtonDesktop={NextButtonDesktop}
      StepComponent={StepComponent}
    />
  );
};

export default WelcomeStepWrapper;
