import { Nullable } from "sonobello.utilities.react";

import { IFlow } from "../../Types/IFlow";
import { IExperimentConfiguration } from "./ExperimentConfiguration";
import ExperimentKey from "./ExperimentKey";
import IExperimentContextProps from "./IExperimentContextProps";
import IFlowResolver, { IFlowAssignment } from "./IFlowResolver";

/**
 * An {@link IFlowResolver} which returns the configured experimental flow if the input experiment matches the configured
 * constraints and the experiment context successfully assigns that experience. Throws otherwise.
 */
abstract class BaseExperimentalFlowResolver implements IFlowResolver {
  private readonly _experimentalFlowName: string;
  private readonly _defaultFlowName: string;
  private readonly _experimentalFlow: IFlow;
  private readonly _experimentKey: ExperimentKey;

  /**
   * @param experimentalFlowName - The name of the experimental flow assigned by this resolver.
   * @param defaultFlowName - The name of the flow which will trigger an evaluation for assignment to the experimental flow.
   * @param experimentalFlow - The flow to return the resolver successfully matches the flow name and the experiment is assigned.
   * @param experimentKey - The key of the experiment to evaluate for assignment to the experimental flow.
   */
  constructor(
    experimentalFlowName: string,
    defaultFlowName: string,
    experimentalFlow: IFlow,
    experimentKey: ExperimentKey
  ) {
    this._defaultFlowName = defaultFlowName;
    this._experimentalFlowName = experimentalFlowName;
    this._experimentalFlow = experimentalFlow;
    this._experimentKey = experimentKey;
  }

  readonly invoke = (experimentContext: IExperimentContextProps, sessionFlowName: string): IFlowAssignment => {
    const isExperimentNameExactMatch = sessionFlowName === this._experimentalFlowName;
    if (isExperimentNameExactMatch || sessionFlowName === this._defaultFlowName) {
      let experimentConfig: Nullable<IExperimentConfiguration> = null;
      try {
        experimentConfig = experimentContext.getExperimentConfiguration(this._experimentKey);
        if (experimentConfig.isActive || isExperimentNameExactMatch)
          return { flow: this._experimentalFlow, experimentConfiguration: experimentConfig };
      } catch (e) {
        console.error(e, e);
        console.warn(`Failed to retrieve feature flags for experiment ${this._experimentKey}.`);
        throw new Error(BaseExperimentalFlowResolver.Messages.assignmentDecisionThrew);
      }
    }
    throw new Error(BaseExperimentalFlowResolver.Messages.flowNotResolved);
  };

  static readonly Messages = {
    flowNotResolved: "Flow could not be resolved.",
    assignmentDecisionThrew:
      "An error occurred while attempting to determine assignment status of the experimental flow."
  };
}

export default BaseExperimentalFlowResolver;
