import { Box, Stack, SvgIconProps, Typography } from "@mui/material";
import React, { PropsWithChildren, ReactElement } from "react";
import { SBPalette } from "sonobello.utilities.react.mui";

import { isMobileView } from "../../utils/Constants";
import UserEventNextButtonDesktop from "../../V2/Steps/Compositions/UserEventNextButtonDesktop";
import UserEventNextButtonMobile from "../../V2/Steps/Compositions/UserEventNextButtonMobile";
import INextButtonProps from "../../V2/Steps/Types/INextButtonProps";
import NeedHelpDialog from "../../V2/Views/Components/NeedHelpDialog";

interface ViewWrapperProps {
  /** The header text for the view. */
  header: string;
  /** Optional icon to render at the top of the view. It should use {@link ViewWrapperIconProps}.*/
  icon?: ReactElement;
  /** Optional custom props for the view Next button.
   * Defaults to the 'Back to Sono Bello' button behavior.
   */
  nextButtonProps?: Omit<INextButtonProps, "onEvent" | "isLoading">;
  /** Optional override to hide the 'need help' dialog. Defaults to false. */
  hideNeedHelpDialog?: boolean;
  /** Optional subheader text for the view. */
  subheader?: string;
}

const defaultButtonProps: INextButtonProps = {
  label: "Back to Sono Bello",
  onClick: () => (location.href = process.env.REACT_APP_ESCAPE_URL || ""),
  isLoading: false,
  onEvent: () => null
};

export const ViewWrapperIconProps: SvgIconProps = { sx: { fontSize: 70 }, color: "primary" };

const ViewWrapper: React.FC<PropsWithChildren<ViewWrapperProps>> = ({
  header,
  icon,
  nextButtonProps = defaultButtonProps,
  hideNeedHelpDialog,
  subheader,
  children
}) => {
  const showMobile = isMobileView();
  return (
    <Stack
      spacing={2}
      sx={{
        height: "100%",
        px: showMobile ? 2 : 6,
        backgroundColor: SBPalette.light.white.main,
        pb: showMobile ? 10 : 0
      }}
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        mb={showMobile ? 0 : 3}
        paddingTop={showMobile ? 2 : "25%"}
      >
        {icon}
      </Box>
      <Stack spacing={1}>
        <Typography id="viewWrapper_header" variant={showMobile ? "h5" : "h4"} fontWeight="bold" color="primary">
          {header}
        </Typography>
        {subheader && (
          <Typography id="viewWrapper_subheader" variant="body1">
            {subheader}
          </Typography>
        )}
      </Stack>
      <Box>{children}</Box>
      {!hideNeedHelpDialog && <NeedHelpDialog />}
      {showMobile ? (
        <UserEventNextButtonMobile {...nextButtonProps} isLoading={false} />
      ) : (
        <UserEventNextButtonDesktop {...nextButtonProps} isLoading={false} />
      )}
    </Stack>
  );
};

export default ViewWrapper;
