import React from "react";
import { Navigate } from "react-router-dom";

import { AppContextProps } from "../../../AppContext";
import { GetStepPath } from "../../../types/Step";

export interface IRedirectToFirstStepProps {
  /** The flow to redirect to the first step of. */
  appContextProps: AppContextProps;
}

/** A simple redirect to the first step for the provided flow. */
const RedirectToFirstStep: React.FC<IRedirectToFirstStepProps> = ({ appContextProps }) => {
  const firstStepPath = GetStepPath(appContextProps.flowCState.GetFirstStep());
  return <Navigate to={firstStepPath} />;
};

export default RedirectToFirstStep;
