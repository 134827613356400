import React, { ComponentProps } from "react";
import { useOutletContext } from "react-router-dom";

import AppContextValueProvider from "../../../App/Compositions/AppContextValueProvider";
import { IFlowStepProps } from "../../../Types/IFlow";
import NoBookFlow from "../../../Types/NoBookFlow";
import RebookFlow from "../../../Types/RebookFlow";
import { IStepWrapperButtonProps } from "../../Components/StepWrapper";
import NoBookWelcomeStep from "../Components/NoBookWelcomeStep";
import RebookWelcomeStep from "../Components/RebookWelcomeStep";
import WelcomeStepFlowSwitch, {
  IWelcomeStepFlowSwitchConfig,
  IWelcomeStepFlowSwitchProps
} from "../Components/WelcomeStepFlowSwitch";
import WelcomeStepWrapper, { IWelcomeStepWrapperConfig } from "../Components/WelcomeStepWrapper";
import IWelcomeStepProps from "../Types/IWelcomeStepProps";

/** The mapping for flows that include the welcome step to their specific welcome step component. */
const flowComponentMap: IWelcomeStepFlowSwitchConfig["flowComponentMap"] = {
  [NoBookFlow.flowName]: NoBookWelcomeStep,
  [RebookFlow.flowName]: RebookWelcomeStep
};

const ConfiguredWelcomeStepFlowSwitch: React.FC<
  Omit<ComponentProps<typeof WelcomeStepFlowSwitch>, keyof IWelcomeStepFlowSwitchConfig>
> = props => WelcomeStepFlowSwitch({ flowComponentMap, ...props });

const AppContextWelcomeWelcomeStepFlowSwitch: React.FC = AppContextValueProvider<
  IWelcomeStepFlowSwitchProps & IWelcomeStepProps,
  ComponentProps<typeof ConfiguredWelcomeStepFlowSwitch>
>({
  Component: ConfiguredWelcomeStepFlowSwitch,
  map: ({ customerCState, flowCState }) => ({
    flowName: flowCState.name,
    customerDisplayFirstName: customerCState.displayFirstName,
    navigatorItemCount: flowCState.navigatorItems.length
  })
});

const ConfiguredWelcomeStepWithWrapper: React.FC<
  Omit<ComponentProps<typeof WelcomeStepWrapper>, keyof IWelcomeStepWrapperConfig | keyof IFlowStepProps>
> = props => {
  const flowStepProps = useOutletContext<IFlowStepProps>();
  return <WelcomeStepWrapper StepComponent={AppContextWelcomeWelcomeStepFlowSwitch} {...props} {...flowStepProps} />;
};

const AppContextWelcomeWelcomeStepWrapper: React.FC<IStepWrapperButtonProps> = AppContextValueProvider<
  Omit<ComponentProps<typeof ConfiguredWelcomeStepWithWrapper>, keyof IStepWrapperButtonProps>,
  ComponentProps<typeof ConfiguredWelcomeStepWithWrapper>
>({
  Component: ConfiguredWelcomeStepWithWrapper,
  map: appContextProps => ({ appContextProps })
});

export default AppContextWelcomeWelcomeStepWrapper;
