import { Chip } from "@mui/material";
import React from "react";

import EnvironmentConfiguration from "../../../constants/EnvironmentConfiguration";
import { isMobileView } from "../../../utils/Constants";

/** If the current environment name is not `Production`, this renders as a small {@link Chip} label containing the
 * current software version number. Otherwise, renders nothing.
 */
const VersionWatermark: React.FC = () => {
  const isMobile = isMobileView();
  return EnvironmentConfiguration.name !== "Production" ? (
    <Chip
      size={isMobile ? "small" : undefined}
      label={`v${EnvironmentConfiguration.version}`}
      sx={{
        position: "fixed",
        bottom: 8,
        right: 8,
        opacity: isMobile ? 0.5 : 1,
        fontSize: isMobile ? 10 : 14,
        pointerEvents: "none"
      }}
    />
  ) : null;
};

export default VersionWatermark;
