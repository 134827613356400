import { MedicalForm, MedicalFormState } from "../../../types/MedicalForm";
import { Step } from "../../../types/Step";
import { FlowStepsConfiguration } from "../../Types/IFlow";
import NavigatorItemId from "../Types/NavigatorItemId";

export const welcomeStepConfiguration: FlowStepsConfiguration = {
  [Step.welcome]: {
    activeNavigatorItem: NavigatorItemId.Welcome,
    key: Step.welcome,
    nextStep: ({ customerCState }) => (customerCState.isPreviouslyQualified ? Step.scheduling : Step.qualify)
  }
};

/** The configured steps for screening qualified customers including qualify and medical steps. */
export const screeningStepConfigurations: FlowStepsConfiguration = {
  [Step.qualify]: {
    activeNavigatorItem: NavigatorItemId.Screening,
    key: Step.qualify,
    nextStep: ({ customerCState: { height, weight, dateOfBirth }, disqualifyingReasonsCState }) =>
      height && weight && dateOfBirth && disqualifyingReasonsCState ? Step.medicalPartOne : undefined
  },
  [Step.medicalPartOne]: {
    activeNavigatorItem: NavigatorItemId.Screening,
    key: Step.medicalPartOne,
    nextStep: ({ medicalFormCState, disqualifyingReasonsCState }) => {
      const formResult = MedicalForm.getResultPart(medicalFormCState.partOneQuestions);
      if (disqualifyingReasonsCState && formResult !== MedicalFormState.Incomplete) return Step.medicalPartTwo;
      return undefined;
    }
  },
  [Step.medicalPartTwo]: {
    activeNavigatorItem: NavigatorItemId.Screening,
    key: Step.medicalPartTwo,
    nextStep: ({ medicalFormCState, disqualifyingReasonsCState }) => {
      const formResult = MedicalForm.getResultPart(medicalFormCState.partTwoQuestions);
      if (disqualifyingReasonsCState && formResult !== MedicalFormState.Incomplete) return Step.medicalA1C;
      return undefined;
    }
  },
  [Step.medicalA1C]: {
    activeNavigatorItem: NavigatorItemId.Screening,
    key: Step.medicalA1C,
    nextStep: ({ medicalFormCState, disqualifyingReasonsCState }) => {
      const formResult = MedicalForm.getResult(medicalFormCState);
      if (disqualifyingReasonsCState && formResult !== MedicalFormState.Incomplete) return Step.scheduling;
      return undefined;
    }
  }
};

/** The configured scheduling and booking steps that support changing the center. */
export const centerSelectableBookingStepConfigurations: FlowStepsConfiguration = {
  [Step.scheduling]: {
    activeNavigatorItem: NavigatorItemId.Scheduling,
    key: Step.scheduling,
    nextStep: ({ reservation }) => (reservation && Step.confirmation) || undefined
  },
  [Step.centerSelection]: {
    activeNavigatorItem: NavigatorItemId.Scheduling,
    key: Step.centerSelection,
    nextStep: () => Step.scheduling
  },
  [Step.confirmation]: {
    activeNavigatorItem: NavigatorItemId.Confirmation,
    key: Step.confirmation,
    nextStep: null
  }
};

/** The configured scheduling and booking steps that do not allow for the center to be changed. */
export const staticCenterBookingStepConfigurations: FlowStepsConfiguration = {
  [Step.scheduling]: centerSelectableBookingStepConfigurations[Step.scheduling],
  [Step.confirmation]: centerSelectableBookingStepConfigurations[Step.confirmation]
};
