import { MedicalForm, MedicalFormState } from "../../../types/MedicalForm";
import { Step } from "../../../types/Step";
import { INavigatorItemConfig } from "../Components/Navigator";
import NavigatorItemId from "../Types/NavigatorItemId";

export const WelcomeNavigatorConfiguration: INavigatorItemConfig = {
  id: NavigatorItemId.Welcome,
  label: "Welcome",
  isDisabled: () => false,
  onClickStep: Step.welcome,
  lastStep: Step.welcome
};

const ScreeningNavigatorConfiguration: INavigatorItemConfig = {
  id: NavigatorItemId.Screening,
  label: "Screening",
  isDisabled: () => false,
  onClickStep: Step.qualify,
  lastStep: Step.medicalA1C
};

const SchedulingNavigatorConfiguration: INavigatorItemConfig = {
  isDisabled: ({ medicalFormCState }) => MedicalForm.getResult(medicalFormCState) !== MedicalFormState.Valid,
  id: NavigatorItemId.Scheduling,
  label: "Scheduling",
  onClickStep: Step.centerSelection,
  lastStep: Step.scheduling
};

const ConfirmationNavigatorConfiguration: INavigatorItemConfig = {
  isDisabled: ({ reservation }) => !reservation,
  id: NavigatorItemId.Confirmation,
  label: "Confirmation",
  onClickStep: Step.confirmation,
  lastStep: Step.confirmation
};

/** The default navigator configuration for the application. It includes the qualify, scheduling, and confirmation
 *  navigator items.
 */
export const DefaultNavigatorConfiguration: INavigatorItemConfig[] = [
  ScreeningNavigatorConfiguration,
  SchedulingNavigatorConfiguration,
  ConfirmationNavigatorConfiguration
];

/** The navigator configuration that should only be used when the customer is pre-qualified for the booking process. */
export const PreQualifiedCustomerNavigatorConfiguration: INavigatorItemConfig[] = [
  SchedulingNavigatorConfiguration,
  ConfirmationNavigatorConfiguration
];
