import { DateTime } from "luxon";
import React, { ComponentProps, useCallback, useState } from "react";
import { IDisable, IInitializedMutator, Nullable } from "sonobello.utilities.react";

import { LocalStorageConfigs, loadFromLocal, saveToLocal } from "../../../../utils/LocalStorage";
import ICustomerQualification from "../../../Types/ICustomerQualification";
import { IQualifyStepWrapperConfig } from "./QualifyStepWrapper";

export interface IPrimitiveCustomerQualification {
  /** The primitive value of the customer's height. */
  height: {
    /** The primitive value of the customer's height in feet. */
    feet: string;
    /** The primitive value of the customer's height in inches. */
    inches: string;
  };
  /** The primitive value of the customer's weight. */
  weight: string;
  /** The primitive value of the customer's date of birth. */
  dateOfBirth: string;
}

export interface IQualifyStepFormValidatorCacheConfig {
  /** The form to render which provides interop to update the primitive form state. */
  Form: React.FC<IInitializedMutator<IPrimitiveCustomerQualification> & IDisable>;
}

export type IQualifyStepFormValidatorCacheProps = ComponentProps<IQualifyStepWrapperConfig["QualifyStep"]>;

const getValidatedQualification = (formData: IPrimitiveCustomerQualification): Nullable<ICustomerQualification> => {
  if (formData.height.feet === "" || formData.weight === "" || !formData.dateOfBirth) return null;
  return {
    height: { feet: Number(formData.height.feet), inches: Number(formData.height.inches || 0) },
    weightInPounds: Number(formData.weight),
    dateOfBirth: DateTime.fromISO(formData.dateOfBirth),
    timeStampUtc: DateTime.utc()
  };
};

/** Maintains the cached state for the child form and resolves its initial state from both the cache.
 * Additionally, updates from the child form are only dispatched to the parent if the whole form state is valid.
 */
const QualifyStepFormValidatorCache: React.FC<
  IQualifyStepFormValidatorCacheProps & IQualifyStepFormValidatorCacheConfig
> = ({ onChange, Form, ...rest }) => {
  const [formInitialValue] = useState<IPrimitiveCustomerQualification>(() => {
    const cachedForm = loadFromLocal<IPrimitiveCustomerQualification>(LocalStorageConfigs.qualifyForm.key);
    return {
      height: {
        feet: String(cachedForm?.height?.feet ?? ""),
        inches: String(cachedForm?.height?.inches ?? "")
      },
      weight: String(cachedForm?.weight ?? ""),
      dateOfBirth: cachedForm?.dateOfBirth ?? ""
    };
  });

  const onFormChange = useCallback((value: IPrimitiveCustomerQualification) => {
    const validForm = getValidatedQualification(value);
    if (validForm) onChange(validForm);
    saveToLocal(value, LocalStorageConfigs.qualifyForm.key);
  }, []);

  return <Form onChange={onFormChange} initialValue={formInitialValue} {...rest} />;
};

export default QualifyStepFormValidatorCache;
