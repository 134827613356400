import React, { useEffect, useState } from "react";
import ReactConfetti from "react-confetti";
import { SBPalette } from "sonobello.utilities.react.mui";

const confettiColors: string[] = [
  SBPalette.light.watermarkOrange.main,
  SBPalette.light.watermarkLightGray.main,
  SBPalette.dark.watermarkGray.main
];

const confetti = {
  leaf: {
    shape: new Path2D(`M5 445 c-17 -136 38 -241 178 -336 51 -36 57 -43 57 -75 0 -21 4 -33
    10 -29 18 11 11 146 -9 191 -18 40 -30 52 -130 126 -53 40 -78 73 -86 113 l-6
    30 17 -30 c25 -45 63 -83 114 -112 66 -38 100 -72 125 -127 22 -46 24 -47 35
    -26 17 31 7 91 -20 128 -13 17 -59 50 -103 74 -45 24 -101 62 -125 86 -25 23
    -46 42 -48 42 -1 0 -6 -25 -9 -55z`),
    aspectRatio: { x: 0.07, y: 0.07 }
  }
};

/** Draw the svg shape for a leaf as a confetti item. */
function drawConfetti(this: { shape?: keyof typeof confetti; isSet?: boolean }, ctx: CanvasRenderingContext2D): void {
  ctx.scale(confetti["leaf"].aspectRatio.x, confetti["leaf"].aspectRatio.y);
  ctx.fill(confetti["leaf"].shape);
}

export const classNames = {
  confetti: "confetti"
};

/** View which thanks the user for confirming their booking and displayed the details of the appointment. */
const Confetti: React.FC = () => {
  const [confettiCountState, setConfettiCountState] = useState(0);
  useEffect(() => setConfettiCountState(100), []);

  return (
    <>
      {Boolean(confettiCountState) && (
        <ReactConfetti
          className={classNames.confetti}
          colors={confettiColors}
          numberOfPieces={confettiCountState}
          onConfettiComplete={() => setConfettiCountState(0)}
          recycle={false}
          drawShape={drawConfetti}
          style={{ position: "absolute" }}
        />
      )}
    </>
  );
};

export default Confetti;
