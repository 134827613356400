import { Stack, Typography } from "@mui/material";
import React from "react";

import EnvironmentConfiguration from "../../../constants/EnvironmentConfiguration";
import EscapePhone from "../../App/Components/EscapePhone";

/** A simple dialog which prompts the user to contact the business at the configured phone number if they need
 * assistance.
 */
const NeedHelpDialog: React.FC = () => (
  <Stack spacing={2}>
    <Typography variant="h5">Need Help?</Typography>
    <Typography variant="body1">
      Please contact us at <EscapePhone phoneNumber={EnvironmentConfiguration.escapePhoneNumber} /> to update your
      information and schedule your consultation.
    </Typography>
  </Stack>
);

export default NeedHelpDialog;
