import { Stack, Typography } from "@mui/material";
import React, { ComponentProps } from "react";

import { IQualifyStepWrapperConfig } from "./QualifyStepWrapper";

export type IQualifyStepProps = ComponentProps<IQualifyStepWrapperConfig["QualifyStep"]>;

export interface IQualifyStepConfig {
  /** The form component which accepts user input to update qualifying information. */
  Form: IQualifyStepWrapperConfig["QualifyStep"];
}

/** The step which displays a form for the user to update their qualifying information. */
const QualifyStep: React.FC<IQualifyStepProps & IQualifyStepConfig> = ({ Form, ...rest }) => {
  return (
    <Stack spacing={2}>
      <Typography variant="body1">
        Please answer a few medical screening questions so we may determine if you are a candidate for our procedures.
      </Typography>
      <Typography variant="body1">First, we&apos;ll need your height, weight, and date of birth:</Typography>
      <Form {...rest} />
    </Stack>
  );
};

export default QualifyStep;
