import React from "react";

import IWelcomeStepProps from "../Types/IWelcomeStepProps";

export interface IWelcomeStepFlowSwitchConfig {
  /** A mapping from the flow name to its welcome step component. */
  flowComponentMap: Record<string, React.FC<IWelcomeStepProps>>;
}

export interface IWelcomeStepFlowSwitchProps {
  /** The name of the flow for the current session. */
  flowName: string;
}

const getMatchingOrFirstComponent = (
  flowComponentMap: Record<string, React.FC<IWelcomeStepProps>>,
  flowName: string
) => {
  let component = flowComponentMap[flowName];
  if (!component) {
    component = Object.values(flowComponentMap)[0];
    console.warn(`No welcome step found for flow name: ${flowName}. Using the first component instead.`);
  }
  return component;
};

/** Renders the component from the given map which matches the keyed flow name. */
const WelcomeStepFlowSwitch: React.FC<
  IWelcomeStepFlowSwitchConfig & IWelcomeStepFlowSwitchProps & IWelcomeStepProps
> = ({ flowComponentMap, flowName, ...rest }) => {
  const Component = getMatchingOrFirstComponent(flowComponentMap, flowName);
  return <Component {...rest} />;
};

export default WelcomeStepFlowSwitch;
