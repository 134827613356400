import React, { ComponentProps } from "react";

import AppContextValueProvider from "../../../App/Compositions/AppContextValueProvider";
import UserEventContextValueProvider from "../../../App/Compositions/UserEventContextValueProvider";
import FlowStepOutletValueProvider from "../../../Routing/Composition/FlowStepOutletValueProvider";
import { IFlowStepProps } from "../../../Types/IFlow";
import ServiceSelector from "../../CenterSelect/Components/ServiceSelector";
import { IStepWrapperButtonProps } from "../../Components/StepWrapper";
import AppContextReservationCounter from "../../Compositions/AppContextReservationCounter";
import IUserEventSource from "../../Types/IUserEventSource";
import { ISchedulingStepConfig } from "../Components/SchedulingStep";
import SchedulingStepWrapper, {
  ISchedulingStepWrapperConfig,
  ISchedulingStepWrapperProps
} from "../Components/SchedulingStepWrapper";
import useCreateLegacyReservation from "../Hooks/UseCreateLegacyReservation";
import useCreateOpsDistanceReservation from "../Hooks/UseCreateOpsDistanceReservation";
import useReleaseLegacyReservation from "../Hooks/UseReleaseLegacyReservation";
import useReleaseOpsReservation from "../Hooks/UseReleaseOpsReservation";
import AppContextSchedulingLoader from "./AppContextSchedulingLoader";
import AppContextSchedulingStep from "./AppContextSchedulingStep";
import AppContextUseCreateReservation from "./AppContextUseCreateReservation";
import AppContextUseReleaseReservation from "./AppContextUseReleaseReservation";
import DynamicScheduleSlotSelector from "./DynamicScheduleSlotSelector";

const RoutedSchedulingStepWrapper = FlowStepOutletValueProvider({
  Component: UserEventContextValueProvider({ Component: SchedulingStepWrapper })
});

const AppContextSchedulingStepWrapper: React.FC<ISchedulingStepWrapperConfig> = AppContextValueProvider<
  Omit<ISchedulingStepWrapperProps, keyof IFlowStepProps | keyof IUserEventSource>,
  ComponentProps<typeof RoutedSchedulingStepWrapper>
>({
  Component: RoutedSchedulingStepWrapper,
  map: ({ bookingController, customerCState, reservation, router, setReservation }) => {
    if (!bookingController) throw "Booking controller is required for scheduling step.";
    return {
      value: reservation?.slot ?? null,
      onChange: setReservation,
      customerFirstName: customerCState.firstName,
      allCentersUnbookable: !bookingController.isBookingPossible,
      router
    };
  }
});

const useCreateReservation: ISchedulingStepWrapperConfig["useCreateReservation"] = () =>
  AppContextUseCreateReservation({
    useCreateLegacyReservation,
    useCreateOpsReservation: useCreateOpsDistanceReservation
  });

const useReleaseReservation: ISchedulingStepConfig["useReleaseReservation"] = () =>
  AppContextUseReleaseReservation({
    useReleaseOpsReservation,
    useReleaseLegacyReservation
  });

const SchedulingStep: ISchedulingStepWrapperConfig["Scheduler"] = props => (
  <AppContextSchedulingStep
    {...props}
    useReleaseReservation={useReleaseReservation}
    ServiceSelector={ServiceSelector}
    SlotSelector={DynamicScheduleSlotSelector}
    ReservationCounter={AppContextReservationCounter}
  />
);

const HybridCalendarSchedulingStep: React.FC<IStepWrapperButtonProps> = props => (
  <AppContextSchedulingLoader>
    <AppContextSchedulingStepWrapper
      {...props}
      useCreateReservation={useCreateReservation}
      Scheduler={SchedulingStep}
    />
  </AppContextSchedulingLoader>
);

export default HybridCalendarSchedulingStep;
