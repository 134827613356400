import { Box, Stack } from "@mui/material";
import React, { Dispatch, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { SBPalette } from "sonobello.utilities.react.mui";

import { AppContextProps } from "../../../AppContext";
import { LeadResultType } from "../../../dtos/LeadResult";
import { GetCurrentStep, GetStepPath, Step } from "../../../types/Step";
import { isMobileView } from "../../../utils/Constants";
import IPathObserver from "../../Routing/Types/IPathObserver";
import { INonCandidateViewRouter, IStepRouter } from "../../Routing/Types/IRouter";
import { FlowStep, IFlowStepProps } from "../../Types/IFlow";
import Navigator from "./Navigator";

export interface IStepProviderProps extends IPathObserver {
  /** The application context properties. */
  appContextProps: AppContextProps;
  /** A connector allowing the user to transfer the session to the 'non-candidate' view or a specified step. */
  router: INonCandidateViewRouter & IStepRouter;
}

/** Renders session steps in the outlet and maintains the current step state. */
const StepProvider: React.FC<IStepProviderProps> = ({ appContextProps, pathName, router }) => {
  const showMobile = isMobileView();

  const [currentStepState, setCurrentStepState] = useState(() => {
    const step = GetCurrentStep(pathName);
    if (step && appContextProps.flowCState.ContainsStep(step)) return step;
    else return appContextProps.flowCState.GetFirstStep();
  });

  const setStep: Dispatch<Step> = (s: Step) => {
    const newPath = GetStepPath(s);
    if (newPath !== pathName) router.goToStep(s);
    setCurrentStepState(s);
  };

  useEffect(() => {
    const currentStep = GetCurrentStep(pathName);
    setCurrentStepState(s => (currentStep && s !== currentStep ? currentStep : s));
  }, [pathName]);

  // TODO Remove this block when Qualify is refactored to CC.
  useEffect(() => {
    if (
      appContextProps.leadResultCState?.type === LeadResultType.ObxNonCandidateBmi ||
      appContextProps.leadResultCState?.type === LeadResultType.ObxNonCandidateMedical
    )
      router.goToNonCandidateView();
  }, [appContextProps.leadResultCState, pathName]);

  return (
    <Stack
      width="100%"
      height="100%"
      py={showMobile ? 1 : 2}
      px={2}
      sx={{ backgroundColor: SBPalette.light.white.main }}
      spacing={showMobile ? 1 : 2}
    >
      <Box display="flex" justifyContent="center" width="100%">
        <Navigator
          appContextProps={appContextProps}
          flowStep={appContextProps.flowCState.steps[currentStepState]!}
          itemConfigurations={appContextProps.flowCState.navigatorItems}
          setStep={setStep}
        />
      </Box>
      <Box display="flex" flexGrow={1}>
        <Outlet
          context={
            { setStep, flowStep: appContextProps.flowCState.steps[currentStepState] as FlowStep } as IFlowStepProps
          }
        />
      </Box>
    </Stack>
  );
};

export default StepProvider;
