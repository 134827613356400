import {
  DefaultNavigatorConfiguration,
  PreQualifiedCustomerNavigatorConfiguration,
  WelcomeNavigatorConfiguration
} from "../App/Compositions/NavigatorConfigurations";
import {
  screeningStepConfigurations,
  staticCenterBookingStepConfigurations,
  welcomeStepConfiguration
} from "../App/Compositions/StepConfigurations";
import { Flow } from "./IFlow";

const rebookFlowName = "rebook";

/** The flow that the user should experience when rebooking a cancelled or no-showed appointment. */
class RebookFlow extends Flow {
  static readonly flowName = rebookFlowName;

  constructor(isCustomerPreQualified: boolean) {
    isCustomerPreQualified
      ? super(rebookFlowName, { ...welcomeStepConfiguration, ...staticCenterBookingStepConfigurations }, [
          WelcomeNavigatorConfiguration,
          ...PreQualifiedCustomerNavigatorConfiguration
        ])
      : super(
          rebookFlowName,
          { ...welcomeStepConfiguration, ...screeningStepConfigurations, ...staticCenterBookingStepConfigurations },
          [WelcomeNavigatorConfiguration, ...DefaultNavigatorConfiguration]
        );
  }
}

export default RebookFlow;
