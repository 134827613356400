import {
  DefaultNavigatorConfiguration,
  PreQualifiedCustomerNavigatorConfiguration,
  WelcomeNavigatorConfiguration
} from "../App/Compositions/NavigatorConfigurations";
import {
  centerSelectableBookingStepConfigurations,
  screeningStepConfigurations,
  welcomeStepConfiguration
} from "../App/Compositions/StepConfigurations";
import { Flow } from "./IFlow";

const noBookFlowName = "noBook";

/** The flow that a no-book guest should experience when booking an appointment. */
class NoBookFlow extends Flow {
  static readonly flowName = noBookFlowName;

  constructor(isCustomerPreQualified: boolean) {
    isCustomerPreQualified
      ? super(noBookFlowName, { ...welcomeStepConfiguration, ...centerSelectableBookingStepConfigurations }, [
          WelcomeNavigatorConfiguration,
          ...PreQualifiedCustomerNavigatorConfiguration
        ])
      : super(
          noBookFlowName,
          { ...welcomeStepConfiguration, ...screeningStepConfigurations, ...centerSelectableBookingStepConfigurations },
          [WelcomeNavigatorConfiguration, ...DefaultNavigatorConfiguration]
        );
  }
}

export default NoBookFlow;
