import {
  DefaultNavigatorConfiguration,
  PreQualifiedCustomerNavigatorConfiguration
} from "../../App/Compositions/NavigatorConfigurations";
import {
  centerSelectableBookingStepConfigurations,
  screeningStepConfigurations
} from "../../App/Compositions/StepConfigurations";
import { Flow } from "../../Types/IFlow";

const noWelcomeNoBookFlowName = "NoWelcomeNoBookFlow";

/** The flow that a no-book guest should experience when booking an appointment. */
class NoWelcomeNoBookFlow extends Flow {
  static readonly flowName = noWelcomeNoBookFlowName;

  constructor(isCustomerPreQualified: boolean) {
    isCustomerPreQualified
      ? super(
          noWelcomeNoBookFlowName,
          centerSelectableBookingStepConfigurations,
          PreQualifiedCustomerNavigatorConfiguration
        )
      : super(
          noWelcomeNoBookFlowName,
          { ...screeningStepConfigurations, ...centerSelectableBookingStepConfigurations },
          DefaultNavigatorConfiguration
        );
  }
}

export default NoWelcomeNoBookFlow;
