import {
  DefaultNavigatorConfiguration,
  PreQualifiedCustomerNavigatorConfiguration
} from "../App/Compositions/NavigatorConfigurations";
import {
  centerSelectableBookingStepConfigurations,
  screeningStepConfigurations
} from "../App/Compositions/StepConfigurations";
import { Flow } from "./IFlow";

const newPatientFlowName = "newPatient";

/** The flow that a new user should experience when booking an appointment. */
class NewPatientFlow extends Flow {
  static readonly flowName = newPatientFlowName;

  constructor(isCustomerPreQualified: boolean) {
    isCustomerPreQualified
      ? super(
          newPatientFlowName,
          { ...centerSelectableBookingStepConfigurations },
          PreQualifiedCustomerNavigatorConfiguration
        )
      : super(
          newPatientFlowName,
          { ...screeningStepConfigurations, ...centerSelectableBookingStepConfigurations },
          DefaultNavigatorConfiguration
        );
  }
}

export default NewPatientFlow;
