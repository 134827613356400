import RebookFlow from "../../Types/RebookFlow";
import BaseExperimentalFlowResolver from "./BaseExperimentalFlowResolver";
import ExperimentKey from "./ExperimentKey";
import NoWelcomeRebookFlow from "./NoWelcomeRebookFlow";

/** A {@link BaseExperimentalFlowResolver} which resolves the {@link NoWelcomeRebookFlow} if the experiment is assigned. */
class NoWelcomeRebookFlowResolver extends BaseExperimentalFlowResolver {
  constructor(isCustomerPreQualified: boolean) {
    super(
      NoWelcomeRebookFlow.flowName,
      RebookFlow.flowName,
      new NoWelcomeRebookFlow(isCustomerPreQualified),
      ExperimentKey.RebookNoWelcomeStep
    );
  }
}

export default NoWelcomeRebookFlowResolver;
