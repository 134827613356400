import React, { useCallback, useEffect } from "react";
import { TriggeredHook } from "sonobello.utilities.react";

import useObx from "../../../utils/UseObx";
import ApiRequestPaths from "../../Constants/ApiRequestPaths";
import { IDeviceReport } from "../Components/DeviceInfoReporter";

interface IDeviceReportWithLeadId extends IDeviceReport {
  /** The lead id associated with the obx session. */
  leadId: string;
}

const useReportDeviceInfo: TriggeredHook<boolean, void, boolean, IDeviceReport> = deviceReport => {
  const [executed, setExecuted] = React.useState(false);
  const { res, err, loading, setReq } = useObx<void, IDeviceReportWithLeadId>("reportDeviceInfo", {
    method: "post"
  });

  const execute = useCallback(
    () => setReq(p => p && { ...p, url: ApiRequestPaths.postDeviceInfo(deviceReport.leadId), payload: deviceReport }),
    []
  );

  useEffect(() => {
    if (res || err) setExecuted(true);
  }, [res, err]);

  return {
    result: executed,
    execute,
    isLoading: loading,
    error: Boolean(err)
  };
};

export default useReportDeviceInfo;
