import { Nullable, Optional } from "sonobello.utilities.react.mui";

import { AppContextProps } from "../../AppContext";
import { Step } from "../../types/Step";
import { INavigatorItemConfig } from "../App/Components/Navigator";
import NavigatorItemId from "../App/Types/NavigatorItemId";

/** The properties governing a step in the current flow. */
export interface FlowStep {
  /** The unique {@link Step} identifier for the step. */
  readonly key: Step;
  /** The id of the navigator item that should be styled as 'active' when this step is currently active. */
  readonly activeNavigatorItem?: NavigatorItemId;
  /** A function which controls the next step of the current flow.
   * If the prop is `null`, then there is no next possible step.
   * @returns `undefined` indicates that the next step is disabled for some reason.
   * @returns The next {@link Step} that the user should experience if they progress.
   */
  readonly nextStep: Nullable<(props: AppContextProps) => Optional<Step>>;
}

export type FlowStepsConfiguration = Partial<Record<Step, FlowStep>>;

export interface IFlow {
  /** The simple name of the flow.
   * @remarks All step paths should be a child of `app/FLOW_NAME`.
   */
  readonly name: string;
  /** The configuration for the steps which compose this flow.
   * @remarks The first declared step in the flow is automatically navigated to when a flow is authenticated.
   */
  readonly steps: FlowStepsConfiguration;
  /** The configuration for the stepper rendered during this flow. */
  readonly navigatorItems: INavigatorItemConfig[];

  /** Return a flag indicating if the provided flow contains the specified step. */
  readonly ContainsStep: (step: Step) => boolean;

  /** Get the first step that has no defined skip behavior, or succeeds its skip definition for the given flow given the
   * current app context.
   */
  readonly GetFirstStep: () => Step;
}

/** A series of steps which a user will experience in their interaction with the OBX.
 * @remarks The first flow item is always displayed at the beginning of the flow.
 * Stepper items are displayed in the same order in which they are ordered on the object.
 */
export class Flow implements IFlow {
  readonly name: string;
  readonly steps: Partial<Record<Step, FlowStep>>;
  readonly navigatorItems: INavigatorItemConfig[];

  constructor(name: string, steps: IFlow["steps"], navigatorItems: INavigatorItemConfig[]) {
    this.name = name;
    this.steps = steps;
    this.navigatorItems = navigatorItems;
  }

  /** Return a flag indicating if the provided flow contains the specified step. */
  readonly ContainsStep = (step: Step): boolean => Object.keys(this.steps).some(k => Number(k) === step);

  /** Returns the configured step with the smallest index value that the user should experience. */
  readonly GetFirstStep = (): Step => {
    const flowSteps = Object.keys(this.steps);
    if (!flowSteps.length) throw new Error("No valid first step found in flow.");
    return Number(flowSteps[0]) as Step;
  };
}

/** An interface for properties that should be made available to each step in the session, allowing them to
 * self-identify their own context and change the current step for the session.
 */
export interface IFlowStepProps {
  /** The flow item which controls the flow steps' major functionality. */
  readonly flowStep: FlowStep;
  /** Callback to set the current flow step. */
  readonly setStep: (step: Step) => void;
}
