import {
  DefaultNavigatorConfiguration,
  PreQualifiedCustomerNavigatorConfiguration
} from "../../App/Compositions/NavigatorConfigurations";
import {
  screeningStepConfigurations,
  staticCenterBookingStepConfigurations
} from "../../App/Compositions/StepConfigurations";
import { Flow } from "../../Types/IFlow";

const noWelcomeRebookFlowName = "NoWelcomeRebookFlow";

/** The flow that the user should experience when rebooking a cancelled or no-showed appointment. */
class NoWelcomeRebookFlow extends Flow {
  static readonly flowName = noWelcomeRebookFlowName;

  constructor(isCustomerPreQualified: boolean) {
    isCustomerPreQualified
      ? super(
          noWelcomeRebookFlowName,
          staticCenterBookingStepConfigurations,
          PreQualifiedCustomerNavigatorConfiguration
        )
      : super(
          noWelcomeRebookFlowName,
          { ...screeningStepConfigurations, ...staticCenterBookingStepConfigurations },
          DefaultNavigatorConfiguration
        );
  }
}

export default NoWelcomeRebookFlow;
