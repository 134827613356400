import { ComponentProps } from "react";

import AppContextValueProvider from "../../../App/Compositions/AppContextValueProvider";
import FlowStepOutletValueProvider from "../../../Routing/Composition/FlowStepOutletValueProvider";
import { IFlowStepProps } from "../../../Types/IFlow";
import CenterSelectStepWrapper, {
  ICenterSelectStepWrapperConfig,
  ICenterSelectStepWrapperProps
} from "../Components/CenterSelectStepWrapper";

/** A fully composed CenterSelect step. */
const AppContextCenterSelectStepWrapper: React.FC<ICenterSelectStepWrapperConfig> = AppContextValueProvider<
  Omit<ICenterSelectStepWrapperProps, keyof IFlowStepProps>,
  Omit<ComponentProps<typeof CenterSelectStepWrapper>, keyof IFlowStepProps>
>({
  Component: FlowStepOutletValueProvider({ Component: CenterSelectStepWrapper }),
  map: ({ bookingController, setBookingController, setReservation }) => {
    if (!bookingController?.selected) throw "A center must be selected for booking.";
    return {
      clearReservation: () => setReservation(null),
      value: {
        center: bookingController.selected.center,
        service: bookingController.selected.schedule.service
      },
      onChange: ({ center, service }) => setBookingController(bookingController.selectCenter(center.id, service.id))
    };
  }
});

export default AppContextCenterSelectStepWrapper;
