import { Box, debounce, styled } from "@mui/material";
import { DateTime } from "luxon";
import React, { ComponentProps, useCallback, useEffect, useRef } from "react";
import { Nullable } from "sonobello.utilities.react";
import { IValidatedTextFieldProps, ValidatedTextField } from "sonobello.utilities.react.mui";

import { isMobileView } from "../../../../utils/Constants";
import DateOfBirthPicker from "../../../../V2/Steps/Qualify/Components/DateOfBirthPicker";
import { IPrimitiveCustomerQualification, IQualifyStepFormValidatorCacheConfig } from "./QualifyStepFormValidatorCache";

const minDatePickerValue = DateTime.now().minus({ years: 120 });

export const classNames = {
  feetField: "qualifyStepForm_feetField",
  inchesField: "qualifyStepForm_inchesField",
  weightField: "qualifyStepForm_weightField"
};

interface IPrimitiveCustomerQualificationWithDateTime extends IPrimitiveCustomerQualification {
  /** The full {@link DateTime} value of the {@link IPrimitiveCustomerQualification.dateOfBirth} property. */
  dateOfBirthDateTime: Nullable<DateTime>;
}

const QualifyField = styled(ValidatedTextField, { shouldForwardProp: prop => prop !== "isMobile" })<
  IValidatedTextFieldProps & { isMobile: boolean }
>(({ isMobile }) => ({ maxWidth: isMobile ? undefined : "10rem" }));

/** The form which provides user interop with the qualification state. Only dispatches debounced changes. */
const QualifyStepForm: IQualifyStepFormValidatorCacheConfig["Form"] = ({
  disabled,
  initialValue,
  onChange
}: ComponentProps<IQualifyStepFormValidatorCacheConfig["Form"]>) => {
  const showMobile = isMobileView();
  const initialRenderRef = useRef(true);
  const [formState, setFormState] = React.useState<IPrimitiveCustomerQualificationWithDateTime>({
    height: { feet: String(initialValue.height.feet ?? ""), inches: String(initialValue.height.inches ?? "") },
    weight: String(initialValue.weight ?? ""),
    dateOfBirth: initialValue.dateOfBirth,
    dateOfBirthDateTime: DateTime.fromISO(initialValue.dateOfBirth)
  });
  const debouncedOnChange = useCallback(
    debounce(
      ({ height, weight, dateOfBirth }: IPrimitiveCustomerQualificationWithDateTime) =>
        onChange({ height, weight, dateOfBirth }),
      500
    ),
    [onChange]
  );

  useEffect(() => {
    if (!initialRenderRef.current) debouncedOnChange(formState);
    initialRenderRef.current = false;
  }, [debouncedOnChange, formState]);

  const handleFeetChange = useCallback(
    (value?: string) => setFormState(c => ({ ...c, height: { ...c.height, feet: value ?? "" } })),
    []
  );

  const handleInchesChange = useCallback(
    (value?: string) => setFormState(c => ({ ...c, height: { ...c.height, inches: value ?? "" } })),
    []
  );

  const handleWeightChange = useCallback((value?: string) => setFormState(c => ({ ...c, weight: value ?? "" })), []);

  const handleDateChange = useCallback((value: DateTime | null) => {
    setFormState(c => ({ ...c, dateOfBirthDateTime: value, dateOfBirth: value?.toISODate() || "" }));
  }, []);

  return (
    <>
      <QualifyField
        className={classNames.feetField}
        inputProps={{ type: "tel" }}
        label="Height Feet"
        value={formState.height.feet}
        onChange={handleFeetChange}
        rejectInput={input => !/^\d{0,1}$/.test(input) || Number(input) > 8 || input === "0"}
        disabled={disabled}
        isMobile={showMobile}
      />
      <QualifyField
        className={classNames.inchesField}
        inputProps={{ type: "tel" }}
        label="Height Inches"
        value={formState.height.inches}
        onChange={handleInchesChange}
        rejectInput={input => !/^\d{0,2}$/.test(input) || Number(input) > 11}
        disabled={disabled}
        isMobile={showMobile}
      />
      <QualifyField
        className={classNames.weightField}
        inputProps={{ type: "tel" }}
        label="Weight (lbs)"
        value={formState.weight}
        onChange={handleWeightChange}
        isMobile={showMobile}
        rejectInput={input => !/^\d{0,3}$/.test(input)}
        disabled={disabled}
        fullWidth={false}
      />
      <Box pt={1}>
        <DateOfBirthPicker
          startDate={minDatePickerValue}
          value={formState.dateOfBirthDateTime}
          onChange={handleDateChange}
          disabled={disabled}
        />
      </Box>
    </>
  );
};

export default QualifyStepForm;
