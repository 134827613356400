import { ComponentProps } from "react";
import { IComponentParent } from "sonobello.utilities.react";

import { ProtectedAppContextProvider } from "../../../AppContext";
import NewPatientFlow from "../../Types/NewPatientFlow";
import NoBookFlow from "../../Types/NoBookFlow";
import RebookFlow from "../../Types/RebookFlow";
import ExperimentAssignmentAppContextProvider, {
  IExperimentAssignmentAppContextProviderProps
} from "../Components/ExperimentAssignmentAppContextProvider";
import useCreateCustomerExperimentManualAuth from "../Hooks/UseCreateCustomerExperiment";
import FailoverFlowResolver from "../Types/FailoverFlowResolver";
import NameMatchFlowResolver from "../Types/NameMatchFlowResolver";
import NoWelcomeNoBookFlow from "../Types/NoWelcomeNoBookFlow";
import NoWelcomeNoBookFlowResolver from "../Types/NoWelcomeNoBookFlowResolver";
import NoWelcomeRebookFlow from "../Types/NoWelcomeRebookFlow";
import NoWelcomeRebookFlowResolver from "../Types/NoWelcomeRebookFlowResolver";
import ExperimentContextValueProvider from "./ExperimentContextValueProvider";

const configuredExperimentAssignmentAppContextProvider: React.FC<
  IExperimentAssignmentAppContextProviderProps
> = props =>
  ExperimentAssignmentAppContextProvider({
    ...props,
    flowResolver: new FailoverFlowResolver([
      new NoWelcomeNoBookFlowResolver(props.session.customer.isPreviouslyQualified),
      new NoWelcomeRebookFlowResolver(props.session.customer.isPreviouslyQualified),
      new NameMatchFlowResolver([
        new NewPatientFlow(props.session.customer.isPreviouslyQualified),
        new RebookFlow(props.session.customer.isPreviouslyQualified),
        new NoBookFlow(props.session.customer.isPreviouslyQualified),
        new NoWelcomeNoBookFlow(props.session.customer.isPreviouslyQualified),
        new NoWelcomeRebookFlow(props.session.customer.isPreviouslyQualified)
      ])
    ]),
    useCreateCustomerExperiment: useCreateCustomerExperimentManualAuth,
    AppContext: ProtectedAppContextProvider
  });

const ExperimentContextAppContextProvider: React.FC<
  IComponentParent & Omit<IExperimentAssignmentAppContextProviderProps, "experimentContextProps">
> = ExperimentContextValueProvider<
  Pick<IExperimentAssignmentAppContextProviderProps, "experimentContextProps">,
  ComponentProps<typeof configuredExperimentAssignmentAppContextProvider>
>({
  Component: configuredExperimentAssignmentAppContextProvider,
  map: experimentContextProps => ({ experimentContextProps })
});

export default ExperimentContextAppContextProvider;
