import {
  Step as MuiStep,
  StepButton as MuiStepButton,
  StepProps as MuiStepProps,
  Stepper as MuiStepper,
  styled
} from "@mui/material";
import React, { Dispatch } from "react";

import { AppContextProps } from "../../../AppContext";
import { Step } from "../../../types/Step";
import { FlowStep } from "../../Types/IFlow";
import NavigatorItemId from "../Types/NavigatorItemId";

export const classNames = {
  navigationItem: "navigationItem-root",
  activeNavigationItem: "navigationItem-active"
};

export interface INavigatorItemConfig {
  /** The assigned id of the navigator item. */
  id: NavigatorItemId;
  /** The item's label to be shown to the user. */
  label: string;
  /** The last step associated with this navigator item. When the active step exceeds this step, the navigator item
   * should be considered complete.
   */
  lastStep: Step;
  /** The step to navigate to when the item is clicked. */
  onClickStep: Step;
  /** Whether or not the navigator item should be disabled for interaction. */
  isDisabled: (contextProps: AppContextProps) => boolean;
}

interface INavigatorProps {
  /** The full application context. */
  appContextProps: AppContextProps;
  /** The configurations for the behaviors of the individual navigator items. */
  itemConfigurations: INavigatorItemConfig[];
  /** The currently active step for the session. */
  flowStep: Pick<FlowStep, "key" | "activeNavigatorItem">;
  /** The callback to set the currently active step. */
  setStep: Dispatch<Step>;
}

/** Renders the stepper for the OBX session. */
const Navigator: React.FC<INavigatorProps> = ({ appContextProps, itemConfigurations, flowStep, setStep }) => {
  return (
    <MuiStepper nonLinear activeStep={flowStep.key} alternativeLabel>
      {itemConfigurations.map(({ id, label, lastStep, onClickStep, isDisabled }, index) => {
        const classes = [classNames.navigationItem];
        if (id === flowStep.activeNavigatorItem) classes.push(classNames.activeNavigationItem);
        return (
          <StyledMuiStep
            key={index}
            className={classes.join(" ")}
            disabled={isDisabled(appContextProps)}
            active={id === flowStep.activeNavigatorItem}
            completed={flowStep.key > lastStep}
          >
            <MuiStepButton onClick={() => setStep(onClickStep)}>{label}</MuiStepButton>
          </StyledMuiStep>
        );
      })}
    </MuiStepper>
  );
};

/** A step with custom formatting and margins. */
const StyledMuiStep = styled(MuiStep)<MuiStepProps>(({ theme }) => ({
  "& .MuiStepLabel-label": { marginTop: `${theme.spacing(0.66)} !important` }
}));

export default Navigator;
