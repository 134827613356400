import { useOutletContext } from "react-router-dom";
import { IContextValueProvider } from "sonobello.utilities.react";

import { IFlowStepProps } from "../../Types/IFlow";

function FlowStepOutletValueProvider<TComponent extends Partial<IFlowStepProps>>({
  Component
}: Omit<IContextValueProvider<IFlowStepProps, Partial<IFlowStepProps>, TComponent>, "map">): React.FC<
  Omit<TComponent, keyof IFlowStepProps>
> {
  return componentProps => {
    const flowStepProps = useOutletContext<IFlowStepProps>();
    return Component({ ...componentProps, ...flowStepProps } as TComponent);
  };
}

export default FlowStepOutletValueProvider;
