import CalendarIcon from "@mui/icons-material/CalendarToday";
import { Button, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { Nullable } from "sonobello.utilities.react";

import ViewWrapper from "../../../../components/views/ViewWrapper";
import EnvironmentConfiguration from "../../../../constants/EnvironmentConfiguration";
import { isMobileView } from "../../../../utils/Constants";
import ConsultInfo from "../../../Steps/Components/ConsultInfo";
import ICenter from "../../../Types/ICenter";
import Confetti from "./Confetti";

export interface IThankYouViewProps {
  /** The properties of the appointment that will be shown to the user. */
  appointmentInfo: {
    /** The time in UTC at which the booked appointment is scheduled to start. */
    startTimeUtc: string;
    /** The time in UTC at which the booked appointment is scheduled to end. */
    endTimeUtc: string;
    /** The properties of the center in which the booked appointment will take place. */
    center: Pick<ICenter, "addressParts" | "name" | "phoneNumber" | "timeZone">;
  };
  /** The url at which the user can obtain a calendar event object, if it was successfully resolved. */
  calendarEventUrl: Nullable<string>;
}

export const classNames = {
  calendarEventButton: "thankYouView-CalendarEventButton"
};

/** View which thanks the user for confirming their booking and displayed the details of the appointment. */
const ThankYouView: React.FC<Partial<IThankYouViewProps>> = ({ appointmentInfo, calendarEventUrl }) => {
  const showMobile = isMobileView();
  return (
    <ViewWrapper
      header="Congrats!"
      subheader="Your Consultation is Confirmed."
      nextButtonProps={{
        label: "See Our Success Stories",
        onClick: () => (location.href = EnvironmentConfiguration.thankYouUrl)
      }}
      hideNeedHelpDialog
    >
      <Confetti />
      <Stack justifyContent="center" alignItems="center" spacing={showMobile ? 3 : 6}>
        <Stack spacing={1}>
          <Typography variant="h5">You&apos;re One Step Closer to a New You!</Typography>
          <Typography variant="body1">
            You will receive an email and text message with all your consultation information for your records.
          </Typography>
        </Stack>
        {calendarEventUrl && (
          <Button
            className={classNames.calendarEventButton}
            color="primary"
            startIcon={<CalendarIcon />}
            onClick={() => window.open(calendarEventUrl, "_blank")}
          >
            Add to Calendar
          </Button>
        )}
        {appointmentInfo && (
          <ConsultInfo
            {...appointmentInfo}
            startDateTime={DateTime.fromISO(appointmentInfo.startTimeUtc, { zone: appointmentInfo.center.timeZone })}
            endDateTime={DateTime.fromISO(appointmentInfo.endTimeUtc, { zone: appointmentInfo.center.timeZone })}
          />
        )}
      </Stack>
    </ViewWrapper>
  );
};

export default ThankYouView;
