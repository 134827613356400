import { Dispatch, DispatchWithoutAction } from "react";

import { EndedSessionViewProps } from "../components/views/EndedSessionView";
import { IExpiredReservationViewProps } from "../components/views/ExpiredReservationView";
import { ForbiddenSessionViewProps } from "../components/views/ForbiddenSessionView";
import { NoAvailabilityViewProps } from "../components/views/NoAvailabilityView";
import { FlowStepProgress } from "../dtos/FlowStepProgress";
import { LocalStorageConfigs, loadFromLocal, saveToLocal } from "../utils/LocalStorage";
import Routes from "../V2/Routing/Types/AppPaths";
import {
  IEndedSessionViewRouter,
  IExpiredReservationViewRouter,
  IForbiddenSessionViewRouter,
  IInvalidLinkViewRouter,
  ILoadSessionViewRouter,
  INoAvailabilityViewRouter,
  INonCandidateViewRouter,
  IRestartSessionRouter,
  ISessionViewRouter,
  IStepRouter
} from "../V2/Routing/Types/IRouter";
import { IThankYouViewProps } from "../V2/Views/ThankYou/Components/ThankYouView";
import { GetStepPath, Step } from "./Step";
import { View, ViewKeyMap } from "./Views";

const viewCacheKey = "viewProperties";

/** An object which provides a connection to the Views outside of the OBX session. */
export class ViewConnector
  implements
    IEndedSessionViewRouter,
    IExpiredReservationViewRouter,
    IForbiddenSessionViewRouter,
    IInvalidLinkViewRouter,
    ILoadSessionViewRouter,
    INoAvailabilityViewRouter,
    INonCandidateViewRouter,
    IRestartSessionRouter,
    ISessionViewRouter,
    IStepRouter
{
  /** Callback which routes the user to a specified path. */
  private readonly _navigate: Dispatch<string>;
  /** Callback which restarts the current session for the given session key. */
  private readonly _restartSession: Dispatch<string>;
  /** Callback which destroys all persistence of the current session in the browser cache. */
  private readonly _clearSession: DispatchWithoutAction;
  /** Callback which sets the view properties for the application. */
  private readonly _setViewProps: Dispatch<unknown>;
  private _token?: string;
  private _leadId?: string;
  private _flowId?: string;

  /**
   * @param clearSession - Callback which destroys all persistence of the current session in the browser cache.
   * @param navigate - Callback which routes the user to a specified path.
   * @param restartSession - Callback which restarts the current session for the given session key.
   * @param setViewProps - Callback which sets the view properties for the application.
   */
  constructor(
    clearSession: DispatchWithoutAction,
    navigate: Dispatch<string>,
    restartSession: Dispatch<string>,
    setViewProps: (props: unknown) => void
  ) {
    this._navigate = navigate;
    this._clearSession = clearSession;
    this._restartSession = restartSession;
    this._setViewProps = setViewProps;
  }

  /** @param props - {@link EndedSessionViewProps} */
  goToEndedSessionView = (props: EndedSessionViewProps) => {
    this._reportViewProgress(View.endedSession, true);
    this._clearSession();
    this._setViewProps(props);
    this._cacheViewProps(props, View.endedSession);
    this._navigate(ViewKeyMap.endedSession.uri);
  };

  /** {@inheritdoc IEndedSessionViewRouter.goToExpiredReservationView} */
  goToExpiredReservationView = (props: IExpiredReservationViewProps) => {
    this._reportViewProgress(View.expiredReservation, false);
    localStorage.removeItem(LocalStorageConfigs.legacyReservation.key);
    localStorage.removeItem(LocalStorageConfigs.opsReservation.key);
    this._setViewProps(props);
    this._clearSession();
    this._navigate(ViewKeyMap.expiredReservation.uri);
  };

  /** @param props - {@link ForbiddenSessionViewProps} */
  goToForbiddenSessionView = (props: ForbiddenSessionViewProps) => {
    this._clearSession();
    this._setViewProps(props);
    this._cacheViewProps(props, View.forbidden);
    this._navigate(ViewKeyMap.completed.uri);
  };

  /** @param props - {@link NoAvailabilityViewProps} */
  goToNoAvailabilityView = (props: NoAvailabilityViewProps) => {
    this._reportViewProgress(View.noAvailability, true);
    this._clearSession();
    this._setViewProps(props);
    this._cacheViewProps(props, View.noAvailability);
    this._navigate(ViewKeyMap.noAvailability.uri);
  };

  goToNonCandidateView = () => {
    this._reportViewProgress(View.nonCandidate, true);
    this._clearSession();
    this._navigate(ViewKeyMap.nonCandidate.uri);
  };

  goToInvalidLinkView = () => {
    this._clearSession();
    this._navigate(ViewKeyMap.invalidLink.uri);
  };

  goToSomethingWentWrongView = () => {
    this._reportViewProgress(View.somethingWentWrong, true);
    this._clearSession();
    this._navigate(ViewKeyMap.somethingWentWrong.uri);
  };

  goToStep = (step: Step) => {
    this._navigate(GetStepPath(step));
  };

  /** @param props - {@link IThankYouViewProps} */
  goToThankYouView = (props: IThankYouViewProps) => {
    this._reportViewProgress(View.thankYou, true);
    this._clearSession();
    this._setViewProps(props);
    this._navigate(ViewKeyMap.thankYou.uri);
  };

  /** @see See {@link ISessionViewRouter.goToSessionView}. */
  goToSessionView = () => this._navigate(Routes.stepPrefix);

  /** @see See {@link ILoadSessionViewRouter.goToLoadSessionView}. */
  goToLoadSessionView = () => this._clearSession();

  restartSession = (sessionKey: string) => {
    this._clearSession();
    location.href = `/${sessionKey}`;
  };

  setToken = (updatedToken: string) => (this._token = updatedToken);

  setLeadId = (leadId: string) => (this._leadId = leadId);

  setFlowId = (flowId: string) => (this._flowId = flowId);

  private _cacheViewProps = (props: unknown, view: View) => saveToLocal(new CachedViewProps(props, view), viewCacheKey);

  private _reportViewProgress = (view: View, isEnded: boolean) => {
    if (!this._token || !this._leadId || !this._flowId) return;
    window.fetch(`${process.env.REACT_APP_API_URL}/leads/${this._leadId}/flows/${this._flowId}/steps`, {
      keepalive: true,
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${this._token}` },
      body: JSON.stringify({ name: View[view], isEnded } as FlowStepProgress)
    });
  };
}

/** An object representing stored view properties in case the user navigates back to an OBX view. */
export class CachedViewProps {
  viewProps: unknown;
  view: View;

  constructor(viewProps: unknown, view: View) {
    this.viewProps = viewProps;
    this.view = view;
  }

  /** Constructs a CachedViewProps from the cache and validates that its view props matches the current view via window.location. */
  static loadFromCache(): unknown | undefined {
    const keyMap = Object.values(ViewKeyMap).find(keyMap => window.location.pathname.includes(keyMap.uri));
    if (!keyMap) return undefined;
    const cache = loadFromLocal<CachedViewProps>(viewCacheKey);
    if (!cache || cache.view != keyMap.key) return undefined;
    return cache.viewProps;
  }
}
