import React from "react";

import { LocalStorageConfigs } from "../../../../utils/LocalStorage";
import CachedViewWrapper, { ICachedViewWrapperProps } from "../../Components/CachedViewWrapper";
import ThankYouView from "../Components/ThankYouView";
import { IThankYouViewProps } from "../Components/ThankYouView";

/** A thank you view which caches its received component props. If component props are not passed, it attempts to
 * load them from the cache.
 */
const CachedThankYouView: React.FC<ICachedViewWrapperProps<IThankYouViewProps>> = props => {
  return <CachedViewWrapper {...props} cacheKey={LocalStorageConfigs.thankYouView.key} View={ThankYouView} />;
};

export default CachedThankYouView;
