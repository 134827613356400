import { BrowserInfo, detect as detectDevice } from "detect-browser";
import React from "react";
import { ComponentProps } from "react";
import { CachedStateProvider } from "sonobello.utilities.react";

import { LocalStorageConfigs, loadFromLocalRaw, saveToLocal } from "../../../utils/LocalStorage";
import DeviceInfoReporter, { IDeviceInfoReporterProps } from "../Components/DeviceInfoReporter";
import useReportDeviceInfo from "../Hooks/UseReportDeviceInfo";
import AppContextValueProvider from "./AppContextValueProvider";

const ConfiguredDeviceInfoReporter: React.FC<
  Omit<ComponentProps<typeof DeviceInfoReporter>, "useReportDevice">
> = props => <DeviceInfoReporter {...props} useReportDevice={useReportDeviceInfo} />;

const CachedDeviceInfoReporter: React.FC<
  Omit<ComponentProps<typeof ConfiguredDeviceInfoReporter>, keyof IDeviceInfoReporterProps>
> = props =>
  CachedStateProvider<boolean, ComponentProps<typeof ConfiguredDeviceInfoReporter>>({
    ...props,
    Component: ConfiguredDeviceInfoReporter,
    localStoreKey: LocalStorageConfigs.isDeviceLogged.key,
    getCache: () => loadFromLocalRaw(LocalStorageConfigs.isDeviceLogged.key) === "true",
    writeCache: (key, value) => saveToLocal(value, key)
  });

const AppContextCachedDeviceInfoReporter: React.FC = AppContextValueProvider<
  ComponentProps<typeof CachedDeviceInfoReporter>,
  ComponentProps<typeof CachedDeviceInfoReporter>
>({
  Component: CachedDeviceInfoReporter,
  map: ({ leadIdCState }) => {
    const device = detectDevice() as BrowserInfo;
    return {
      deviceReport: { leadId: leadIdCState, device: device?.os, browser: device?.name, browserVersion: device?.version }
    };
  }
});

export default AppContextCachedDeviceInfoReporter;
