import NoBookFlow from "../../Types/NoBookFlow";
import BaseExperimentalFlowResolver from "./BaseExperimentalFlowResolver";
import ExperimentKey from "./ExperimentKey";
import NoWelcomeNoBookFlow from "./NoWelcomeNoBookFlow";

/** A {@link BaseExperimentalFlowResolver} which resolves the {@link NoWelcomeNoBookFlow} if the experiment is assigned. */
class NoWelcomeNoBookFlowResolver extends BaseExperimentalFlowResolver {
  constructor(isCustomerPreQualified: boolean) {
    super(
      NoWelcomeNoBookFlow.flowName,
      NoBookFlow.flowName,
      new NoWelcomeNoBookFlow(isCustomerPreQualified),
      ExperimentKey.NoBookNoWelcomeStep
    );
  }
}

export default NoWelcomeNoBookFlowResolver;
