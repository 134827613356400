/** A utility for constructing URL paths for requests sent to the OBX server. */
class ApiRequestPaths {
  /** Returns the endpoint to report a critical client error. */
  static errorReport = "errorReport";

  /** Returns the endpoint to get the calendar event url for a given appointment.
   * @param leadId - The id of the session's lead.
   * @param customerId - The id of the customer who will be attending the appointment.
   * @param appointmentId - The id of the appointment to obtain the calendar event url for.
   */
  static getCalendarEventUrl = (leadId: string, customerId: string, appointmentId: string) =>
    `leads/${leadId}/customers/${customerId}/appointments/${appointmentId}/calendarEvent`;

  /** Returns the endpoint path to get the centers available for booking the current session. */
  static getCentersV3 = () => `v3/centers`;

  /** Returns the endpoint path to get the experiments that are active for the current session. */
  static getActiveExperiments = () => `v2/Experiments`;

  /** Returns the endpoint path to report the session's device information. */
  static postDeviceInfo = (leadId: string) => `leads/${leadId}/devices`;

  /** Returns the endpoint path to get a legacy calendar by its own id.
   * @param centerId - The id of the center to which the retrieved calendar belongs.
   * @param calendarId - The id of the calendar to be retrieved.
   */
  static getLegacyCalendar = (centerId: string, calendarId: string) => `v2/centers/${centerId}/calendars/${calendarId}`;

  /** Returns the endpoint path to get a legacy calendar by its center id.
   * @param centerId - The id of the center to which the retrieved calendar belongs.
   */
  static getLegacyCenterCalendar = (centerId: string) => `v2/centers/${centerId}/calendars`;

  /** Returns the endpoint path to create the customer experiment assignment.
   * @param leadId - The id of the session's lead.
   * @param customerId - The id of the customer to be assigned to the experiment.
   * @param experimentId - The id of the experiment to assign the customer to.
   */
  static postCustomerExperiment = (leadId: string, customerId: string, experimentId: string) =>
    `v2/leads/${leadId}/customers/${customerId}/experiments/${experimentId}`;

  /** Returns the endpoint path to get an OPS calendar by its center.
   * @param leadId - The id of the session's lead.
   * @param customerId - The id of the customer who will be booking with the calendar.
   * @param centerId - The id of the center to which the retrieved calendar belongs.
   */
  static getOpsCenterCalendar = (leadId: string, customerId: string, centerId: string) =>
    `v2/ops/leads/${leadId}/customers/${customerId}/centers/${centerId}/calendars`;

  /** Returns the endpoint path to confirm a reserved appointment in the legacy booking system.
   * @param leadId - The id of the sessions lead.
   */
  static postLegacyAppointmentUrl = (leadId: string) => `leads/${leadId}/appointments/confirm`;

  /** Returns the endpoint path to confirm a reserved appointment in the OPS Booking system.
   * @param leadId - The id of the sessions lead.
   * @param customerId - The id of the customer who will be making the reservation.
   * @param centerId - The id of the center where the reservation will be made.
   * @param slotId - The id of the slot which will be reserved.
   */
  static postOpsAppointmentUrlV1_1 = (leadId: string, customerId: string, centerId: string, slotId: number) =>
    `v1_1/ops/leads/${leadId}/customers/${customerId}/centers/${centerId}/appointments/confirm/${slotId}`;

  /** Returns the endpoint path create a reservation in the legacy booking system.
   * @param leadId - The id of the sessions lead.
   * @param centerId - The id of the center where the reservation will be made.
   * @param serviceId - The id of the service which will be conducted at the reserved appointment.
   */
  static postLegacyReservationUrl = (leadId: string, centerId: string, serviceId: string) =>
    `leads/${leadId}/centers/${centerId}/services/${serviceId}/blocks`;

  /** Returns the endpoint path to release a reservation in the legacy booking system.
   * @param leadId - The id of the sessions lead.
   * @param centerId - The id of the center where the reservation was made.
   * @param serviceId - The id of the service which was to be conducted at the reserved appointment.
   */
  static postReleaseLegacyReservationUrl = (leadId: string, centerId: string, serviceId: string) =>
    `leads/${leadId}/centers/${centerId}/services/${serviceId}/blocks`;

  /** Returns the endpoint path create a reservation in the OPS Booking system.
   * @param leadId - The id of the sessions lead.
   * @param customerId - The id of the customer who will be making the reservation.
   * @param centerId - The id of the center where the reservation will be made.
   * @param serviceId - The id of the service which will be conducted at the reserved appointment.
   * @param slotId - The id of the slot which will be reserved.
   * @deprecated Use {@link postOpsReservationUrlV1_1} instead.
   */
  static postOpsReservationUrl = (
    leadId: string,
    customerId: string,
    centerId: string,
    serviceId: string,
    slotId: number
  ) => `ops/leads/${leadId}/customers/${customerId}/centers/${centerId}/services/${serviceId}/slots/${slotId}`;

  /** Returns the endpoint path create a reservation in the OPS Booking system.
   * @param leadId - The id of the sessions lead.
   * @param customerId - The id of the customer who will be making the reservation.
   * @param centerId - The id of the center where the reservation will be made.
   * @param serviceId - The id of the service which will be conducted at the reserved appointment.
   * @param slotId - The id of the slot which will be reserved.
   */
  static postOpsReservationUrlV1_1 = (
    leadId: string,
    customerId: string,
    centerId: string,
    serviceId: string,
    slotId: number
  ) => `v1_1/ops/leads/${leadId}/customers/${customerId}/centers/${centerId}/services/${serviceId}/slots/${slotId}`;

  /** Returns the endpoint path to update the session's customer for customers that are assigned to an OPS supported center.
   * @param leadId - The id of the session's lead.
   * @param customerId - The id of the customer to be updated.
   */
  static putOpsCustomerUrl = (leadId: string, customerId: string) => `ops/leads/${leadId}/customers/${customerId}`;

  /** Returns the endpoint path to update the session's customer for customers that are assigned to a legacy center.
   * @param leadId - The id of the session's lead.
   * @param customerId - The id of the customer to be updated.
   */
  static putLegacyCustomerUrl = (leadId: string, customerId: string) => `leads/${leadId}/customers/${customerId}`;

  /** Returns the endpoint path to update the session's lead result.
   * @param leadId - The id of the session's lead.
   */
  static postLeadResult = (leadId: string) => `leads/${leadId}/result`;

  /** The endpoint path used to obtain the data required for the session to start. */
  static postSessionStart = "v2/SessionStart";

  /** Returns an endpoint to update the current step progress for the session.
   * @param leadId - The id of the session's lead.
   * @param flowId - The id of the session's flow.
   */
  static postStepProgress = (leadId: string, flowId: string) => `leads/${leadId}/flows/${flowId}/steps`;

  /** Returns the endpoint path to be used when updating the auth token. */
  static putToken = () => `token`;
}

export default ApiRequestPaths;
