import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { IComponentParent } from "sonobello.utilities.react";

import { AppContextProps } from "../../../AppContext";
import { GetStepPath } from "../../../types/Step";
import Routes from "../Types/AppPaths";
import IPathObserver from "../Types/IPathObserver";

export interface IAppRootRedirectProps extends IComponentParent, IPathObserver {
  /** The properties of the application context. */
  appContextProps: AppContextProps;
}

const isCurrentLocationAppRoot = (location: string) => location === Routes.stepPrefix;

/** A simple redirect to the first step of the specified flow when the application is at its app route root. */
const AppRootRedirect: React.FC<IAppRootRedirectProps> = ({ appContextProps, pathName, children }) => {
  const [isAtAppRoot, setIsAtAppRoot] = React.useState(() => isCurrentLocationAppRoot(pathName));
  useEffect(() => setIsAtAppRoot(pathName === Routes.stepPrefix), [pathName]);
  const firstStepPath = GetStepPath(appContextProps.flowCState.GetFirstStep());
  return isAtAppRoot ? <Navigate to={firstStepPath} /> : <>{children}</>;
};

export default AppRootRedirect;
