import React, { useEffect, useState } from "react";

import { loadFromLocal, saveToLocal } from "../../../utils/LocalStorage";

interface ICachedViewWrapperConfig<TViewProps> {
  /** The key with which the view's component props can be retrieved if they exist. */
  cacheKey: string;
  /** The view component to be rendered inside of the wrapper and provided with the cached component props if they
   * exist.
   */
  View: React.FC<Partial<TViewProps>>;
}

export interface ICachedViewWrapperProps<TViewProps> {
  /** The props passed to the view by its parent. These are not always available, and so might be `undefined`. */
  viewProps?: TViewProps;
}

/** A functional component which attempts to load a value from cache for the given type and cache key.
 * @remarks Since the state of the local cache can be unpredictable, views wrapped by this component must support
 * {@link Partial} component props, and should support the case where the cache value is not present.
 */
function CachedViewWrapper<TViewProps>({
  cacheKey,
  viewProps,
  View
}: ICachedViewWrapperProps<TViewProps> & ICachedViewWrapperConfig<TViewProps>) {
  useEffect(() => {
    if (viewProps) saveToLocal(viewProps, cacheKey);
  }, []);
  const [viewState] = useState<Partial<TViewProps>>(viewProps || loadFromLocal<TViewProps>(cacheKey) || {});

  return <View {...viewState} />;
}

export default CachedViewWrapper;
