import { Grid, Stack, Typography } from "@mui/material";
import React from "react";

import IWelcomeStepProps from "../Types/IWelcomeStepProps";

export const classNames = {
  stepCountLabel: "noBookWelcomeStep_stepCountLabel"
};

/** The Welcome Step to be used when the flow type is No Book. */
const NoBookWelcomeStep: React.FC<IWelcomeStepProps> = ({ customerDisplayFirstName, navigatorItemCount }) => {
  return (
    <Stack spacing={2}>
      <Grid container spacing={1}>
        <Grid item>
          <Typography variant="h4" component="span" color="primary">
            Hi again,
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="span" color="primary">{` ${customerDisplayFirstName}`}</Typography>
        </Grid>
      </Grid>
      <Typography variant="body1">
        Schedule your free consultation with these{" "}
        <span className={classNames.stepCountLabel}>{navigatorItemCount - 1}</span> easy steps.
      </Typography>
    </Stack>
  );
};

export default NoBookWelcomeStep;
