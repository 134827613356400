import React, { useContext } from "react";
import { useOutletContext } from "react-router-dom";

import AppContext from "../../../../AppContext";
import { IFlowStepProps } from "../../../Types/IFlow";
import { IStepWrapperButtonProps } from "../../Components/StepWrapper";
import QualifyStep, { IQualifyStepConfig } from "../Components/QualifyStep";
import QualifyStepForm from "../Components/QualifyStepForm";
import QualifyStepFormValidatorCache from "../Components/QualifyStepFormValidatorCache";
import QualifyStepWrapper, { IQualifyStepWrapperConfig } from "../Components/QualifyStepWrapper";
import useCreateNonCandidateLeadResult from "../Hooks/UseCreateNonCandidateLeadResult";
import useUpdateHybridCustomer from "../Hooks/UseUpdateHybridCustomer";

const CachedQualifyForm: IQualifyStepConfig["Form"] = props => (
  <QualifyStepFormValidatorCache {...props} Form={QualifyStepForm} />
);

const ComposedQualifyStep: IQualifyStepWrapperConfig["QualifyStep"] = props => (
  <QualifyStep {...props} Form={CachedQualifyForm} />
);

const AppContextQualifyStep: React.FC<IStepWrapperButtonProps> = props => {
  const flowStepProps = useOutletContext<IFlowStepProps>();
  const {
    customerCState,
    defaultCenter,
    bookingController,
    disqualifyingReasonsCState,
    medicalFormCState,
    leadIdCState,
    setCustomerCState,
    setLeadResultCState
  } = useContext(AppContext);
  return (
    <QualifyStepWrapper
      {...props}
      {...flowStepProps}
      customer={customerCState}
      defaultCenter={defaultCenter}
      bookingController={bookingController}
      disqualifyingReasons={disqualifyingReasonsCState || null}
      medicalForm={medicalFormCState}
      leadId={leadIdCState}
      setCustomer={setCustomerCState}
      setLeadResult={setLeadResultCState}
      QualifyStep={ComposedQualifyStep}
      UseCreateNonCandidateLeadResult={useCreateNonCandidateLeadResult}
      UseUpdateCustomer={useUpdateHybridCustomer}
    />
  );
};

export default AppContextQualifyStep;
