import ConvertSDK, { ConvertInterface, LogLevel } from "@convertcom/js-sdk";

import EnvironmentConfiguration from "../../../constants/EnvironmentConfiguration";

// const convertSDK: ConvertInterface = new ConvertSDK({
//   sdkKey: EnvironmentConfiguration.convertDotCom.sdkKey,
//   environment: "local",
//   logger: {
//     logLevel: LogLevel.DEBUG,
//     customLoggers: [] // Allows 3rd party loggers to be passed
//   },
//   bucketing: {
//     hash_seed: 9999, // murmurhash seed
//     max_traffic: 10000, // max hash (representing 100% traffic allocation)
//     excludeExperienceIdHash: false // whether to ignore prefixing the generated hash with the experience id
//   },
//   dataStore: null, // Allows 3rd party data store to be passed (optional)
//   dataRefreshInterval: 300000, // in milliseconds (5 minutes)
//   // data: projectData,
//   events: {
//     batch_size: 10, // max network requests to be released per call
//     release_interval: 10000 // time in milliseconds between network releasing queue (including the intial release)
//   },
//   network: {
//     tracking: false, // can be set to false to disable tracking events
//     cacheLevel: "default", // can be set to 'low' for short-lived cache (for development purposes only)
//     source: "js-sdk" // string identifier indicating the source of network requests
//   }
// } as ConvertConfig);

/** The official Convert.com SDK configured for use with the SonoBello account and OBX project. */
export const convertSDK: ConvertInterface = new ConvertSDK({
  sdkKey: `${EnvironmentConfiguration.convertDotCom.accountId}/${EnvironmentConfiguration.convertDotCom.projectId}`,
  environment: "production",
  logger: {
    logLevel: LogLevel.SILENT,
    customLoggers: [] // Allows 3rd party loggers to be passed
  }
});
