import React, { useEffect } from "react";
import { TriggeredHook } from "sonobello.utilities.react";

import { FlowStepProgress } from "../../../dtos/FlowStepProgress";
import { GetCurrentStep, Step } from "../../../types/Step";
import IPathObserver from "../../Routing/Types/IPathObserver";
import { Flow } from "../../Types/IFlow";
import { IUsePostFlowStepProgressProps } from "../Hooks/UsePostFlowStepProgress";

export interface IReportStepProgressProps extends IPathObserver {
  /** The id of the lead associated with the current obx session. */
  leadId: string;
  /** The id of the flow for the current obx session. */
  flowId: string;
  /** The flow for the current obx session. */
  flow: Flow;
  /** A hook to report the current step progress. */
  usePostFlowStepProgress: TriggeredHook<null, FlowStepProgress, boolean, IUsePostFlowStepProgressProps>;
}

/** Tracks and reports the current session's step progress. */
const ReportStepProgress: React.FC<IReportStepProgressProps> = ({
  leadId,
  pathName,
  flowId,
  flow,
  usePostFlowStepProgress
}) => {
  const { execute } = usePostFlowStepProgress({ leadId, flowId });

  useEffect(() => {
    const currentStep = GetCurrentStep(pathName);
    if (currentStep) execute({ name: Step[currentStep], isEnded: !flow.steps[currentStep]?.nextStep });
  }, [pathName]);

  return null;
};

export default ReportStepProgress;
