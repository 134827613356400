import { IFlow } from "../../Types/IFlow";
import IExperimentContextProps from "./IExperimentContextProps";
import IFlowResolver, { IFlowAssignment } from "./IFlowResolver";

/**
 * An {@link IFlowResolver} which returns the flow matching the given name if it exists in the list of known
 * supported flows.
 */
class NameMatchFlowResolver implements IFlowResolver {
  private readonly _supportedFlows: IFlow[];

  /** @param supportedFlows - The flows which can be name matched by this resolver. */
  constructor(supportedFlows: IFlow[]) {
    this._supportedFlows = supportedFlows;
  }

  readonly invoke = (_: IExperimentContextProps, sessionFlowName: string): IFlowAssignment => {
    const flow = this._supportedFlows.find(f => f.name === sessionFlowName);
    if (!flow) throw NameMatchFlowResolver.Messages.flowNotFound;
    return { flow, experimentConfiguration: null };
  };

  static readonly Messages = {
    flowNotFound: "Flow not found."
  };
}

export default NameMatchFlowResolver;
